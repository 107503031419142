import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
// import Toast from 'react-bootstrap/Toast';
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../../utils/constant";
import React, { useState, useEffect } from "react";
import Logo from "../../../assets/img/default/companylogo.png";
import { isLogin, setAuthentication } from "../../../utils/auth";
import axios from "axios";

// console.log(baseURL);

export default function Login({ setisAuthenticated }) {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const authenticate = async () => {
      if (await isLogin()) {
        navigate("/");
      }
    };
    authenticate();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
    setIsButtonDisabled(!email || !password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      email,
      password,
    };

    axios
      .post(`${baseURL}auth/login`, payload)
      .then((res) => {
        setAuthentication(res.data.bearerToken);
        localStorage.setItem("token", res.data.bearerToken);
        navigate("/account/dashboard");
        setisAuthenticated(true);
        toast.success("Login Successful", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <>
      <div className="vh-100 d-flex align-items-center justify-content-center bg-login bg-white">
        <Container>
          <Row className="text-center">
            <Col lg={12}>
              {/* <Link to="https://smartqc.io">
                <img
                  src={Logo}
                  className="img-fluid"
                  height={80}
                  width={200}
                  alt="logo"
                />
              </Link> */}
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="mx-auto">
              <Col lg={12} className="p-5 rounded-3 bg-white shadow">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      // placeholder="Email Address"
                      className="border-1 border-bottom rounded-3 form-control-sm"
                      name="email"
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <div className="d-grid gap-2">
                    <Button
                      variant="btn btn-blue"
                      type="submit"
                      className="rounded-3"
                    >
                      Send password reset link
                    </Button>
                  </div>
                </Form>
                <div className="text-center mt-3">
                  <Link to="/" className="text-center text-decoration-none">
                    Back to Sign In
                  </Link>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
