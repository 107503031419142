import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/auth";
import { baseURL } from "../../utils/constant";
import FirstStep from "../../assets/img/create-type/first step.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import SubNav from "../../Components/subNav";
import { Button, Container } from "react-bootstrap";
import { Row, Col, Spinner } from "react-bootstrap";

const CampaginTyle = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [disbleButton, setDisableButton] = useState(false);
  const [step, setStep] = useState(1);
  const [leadsData, setLeadsData] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [disbleButton, setDisableButton] = useState(false);
  const [debouncedSearchTimer, setDebouncedSearchTimer] = useState(null);
  const [leadFields, setLeadFields] = useState([
    { name: "firstName", type: "String", label: "First Name" },
    { name: "lastName", type: "String", label: "Last Name" },
    { name: "companyName", type: "String", label: "Company Name" },
    { name: "jobTitle", type: "String", label: "Job Title" },
    { name: "phoneNumber", type: "String", label: "Phone Number" },
    { name: "email", type: "String", label: "Email Address" },
    { name: "industry", type: "String", label: "Industry" },
  ]);

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const fetchData = () => {
    setLoading(true); // Start loading
    axios
      .get(`${baseURL}leadField/getLeadFields`, {
        headers: { ...headers },
      })
      .then((response) => {
        const responseData = response.data;
        console.log(responseData);
        const leadsArray = Array.isArray(responseData)
          ? responseData
          : responseData.data;
        setLeadsData(leadsArray);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };


  const handleLeadFieldClick = (fieldName) => {
    const isDisabledField = [
      "firstName",
      "lastName",
      "companyName",
      "jobTitle",
      "phoneNumber",
      "email",
      "industry"
    ].includes(fieldName);

    if (isDisabledField) {
      return;
    }
    

    const isSelected = leadFields.find((field) => field.name === fieldName);

    if (isSelected) {
      const updatedLeadFields = leadFields.filter(
        (field) => field.name !== fieldName
      );
      setDisableButton(true);
      setLeadFields(updatedLeadFields);
      setLeadsData((prevLeadsData) => [...prevLeadsData, isSelected]);
    } else {
      const leadField = leadsData.find((field) => field.name === fieldName);
      if (leadField) {
        setLeadFields((prevSelected) => [...prevSelected, leadField]);
      }
      const updatedLeadsData = leadsData.filter(
        (field) => field.name !== fieldName
      );
      setLeadsData(updatedLeadsData);
    }

    // Disable the clicked button
    const updatedLeadsData = leadsData.map((field) =>
      field.name === fieldName ? { ...field, disabled: true } : field
    );
    setLeadsData(updatedLeadsData);
  };


  const handleDone = () => {
    navigate("/createcampaign", {
      state: {
        leadFields,
        campaignName,
      },
    });
  };

  return (
    <>
        {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" variant="info" />
        </div>
      ) : (
        <>
      <SubNav />
      <Container className="py-5">
        <Row>
          <Col lg={10} className="mx-auto">
            <h3 className="mb-5 text-black">New Campaign</h3>
            <Form.Control
              placeholder="Name"
              className="mb-4"
              required
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
            />

            <Row>
              <Col className="mb-4">
                <p>Select the fields for your campaign</p>
                {
                  leadsData.map((leadField) => {
                    const isSelected = leadFields.find(
                      (selected) => selected.name === leadField.name
                    );

                    return (
                      <button
                        key={leadField.id}
                        text="dark"
                        className={` btn border me-2 mb-2 ${
                          isSelected ? "btn-warning text-white" : "active"
                        }`}
                        disabled={false}
                        onClick={() => handleLeadFieldClick(leadField.name)}
                      >
                        {leadField.label} 
                      </button>
                    );
                  })
                }
              </Col>
            </Row>
            <Button variant="btn btn-blue" onClick={handleDone}>
              Done
            </Button>
          </Col>
        </Row>
      </Container>
      </>
      )}
    </>
  );
};

export default CampaginTyle;