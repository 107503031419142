import { Button, Container, Row, Col } from "react-bootstrap";
import { getCookie } from "../../../utils/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import SubNav from "../../../Components/subNav";
import {  useNavigate } from "react-router-dom";

const Billing = () => {
  const navigate = useNavigate();

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const [user, setUser] = useState({ credits: 0 });
  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${baseURL}user/getUserDetailsByUserId`, {
          headers: { ...headers },
        })
        .then((response) => {
          // console.log(response);
          const { credits, status, firstName, lastName } = response.data.data;
          setUser({
            credits,
            firstName,
            lastName,
          });
          // setIsLoading(false);
          if (status !== "Active") {
            // setShowAlert(true);
          }
        })
        .catch((err) => {
          // setIsLoading(false);
          if (err.response && err.response.status === 401) {
            return navigate("/");
          }
          // setIsLoading(false);
        });
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SubNav />
      <Container className="py-5">
        <Row>
          <h3 className="fw-bold mb-5">Billing Information</h3>
      

          <Col lg={2}>
            <div
              id="simple-list-example"
              className="d-flex flex-column gap-2 simple-list-example-scrollspy text-start"
            >
              <a
                className="p-1 rounded text-decoration-none text-dark fw-bold"
                href="#plan"
              >
                {" "}
                Plan
              </a>
              <a
                className="p-1 rounded text-decoration-none text-dark fw-bold"
                href="#creaditbalance"
              >
                {" "}
                Credit Balance
              </a>
              <a
                className="p-1 rounded text-decoration-none text-dark fw-bold"
                href="#invoicehistory"
              >
                {" "}
                Invoice History
              </a>
            </div>
          </Col>
          <Col lg={10}>
            <div
              data-bs-spy="scroll"
              data-bs-target="#simple-list-example"
              data-bs-offset="0"
              data-bs-smooth-scroll="true"
              class="scrollspy-example"
              tabindex="0"
            >
              <div className="border p-5 rounded-3 mb-3">
                <h5 className="border-bottom pb-3" id="plan">
                  Plan
                </h5>
                <br />
                <Row>
                  <Col>
                    <p>Free</p>
                  </Col>
                  <Col className="text-end">
                    <Button
                      variant="outline-secondary"
                      className="ms-2 btn-sm"
                      onClick={() => navigate("/account/billing/plans")}
                    >
                      Change Plan
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="border p-5 rounded-3 mb-3">
                <h5 className="border-bottom pb-3" id="creaditbalance">
                  Credit Balance
                </h5>
                <br />
                <Row>
                  <Col>
                    <p>Total balance</p>
                    <h5>{user.credits}</h5>
                  </Col>
                </Row>
              </div>
              <div className="border p-5 rounded-3 mb-3">
                <h5 id="invoicehistory">
                  Invoice History
                </h5>
                <p className="border-bottom pb-3">
                  View or download your past invoices.
                </p>
                <br />
                <Row>
                  <Col>
                    <p>No past invoices.</p>
                  </Col>
                  <Col className="text-end">
                    <Button
                      variant="outline-secondary"
                      className="ms-2 btn-sm"
                      onClick={() => navigate("/account/billing/invoices")}
                    >
                      View All
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Billing;
