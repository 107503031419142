import { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const stripePromise = loadStripe("pk_test_0jVptHTbonW3K38GrRl7kcwL00gzlJ9yhG");

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/account/billing/invoices",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="d-grid gap-2">
        <button className="btn btn-blue mt-3" disabled={!stripe}>
          Pay Now
        </button>
      </div>
    </form>
  );
};

export default function App() {
  const location = useLocation();
  const { paymentIntentResponse, planName, planDetails, amount, currency } =
    location.state;
  const res = paymentIntentResponse;
  // console.log(amount);
  console.log(currency)
  const options = {
    // passing the client secret obtained from the server
    clientSecret: res.client_secret,
  };

  return (
    <>
      <div className="vh-100 d-flex align-items-center">
        <Container>
          <Row>
            {/* <Col lg={6} className="vh-100 d-flex align-items-center"> */}
            <Col lg={6} className="mx-auto">
              <h3 className="pb-3 border-bottom fw-bold">Order Summary</h3>
              <h5>{planName}</h5>
              <p className="">{planDetails}</p>
              <h5 className="mb-5">
                <p>
                  Total : {amount} {currency.toUpperCase()}
                </p>
              </h5>
              <Col lg={12} className="p-3 border rounded-3">
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm />
                </Elements>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
