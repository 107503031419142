import { Row, Col, Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { getCookie } from "../../../../utils/auth";
import { useState } from "react";
import axios from "axios";
import { baseURL } from "../../../../utils/constant";
import SubNav from "../../../../Components/subNav";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";

const Dashboard = () => {
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [campName, setcampName] = useState("");
  const bearerToken = getCookie("bearerToken");

  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error("Please select a file to upload", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("campaignName", campName);

    try {
      const response = await axios.post(
        `${baseURL}campaign/uploadCampaign`,
        formData,
        { headers: { ...headers } }
      );

      if (response.status === 200) {
        navigate("/account/campaigns");
        setFile(null);
        setcampName("");
        toast.success("Campaigns Uploaded Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        return navigate("/login");
      }
    }
  };

  return (
    <>
      <SubNav />
      <Container className="py-4">
        <Row>
          <h3 className="fw-bold mb-4 text-black">Upload Campaign</h3>
          <Row className="m-auto d-flex justify-content-center py-3">
            <Col lg={4}>
              <Col lg={12} className="p-3 rounded-4 border">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      className="rounded-4"
                      type="text"
                      placeholder="Campaign Name"
                      id="campaignName"
                      onChange={(e) => setcampName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Control
                      type="file"
                      className="rounded-4"
                      id="formFile"
                      onChange={handleFileChange}
                    />
                  </Form.Group>

                  <Button
                    className="d-flex m-auto"
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
