import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import Logo from '../../src/assets/img/default/companylogo.png'


function NavigationBar() {
  return (
    <Navbar expand="lg" className='sticky-top mask'>
      <Container>
        <Navbar.Brand to="/">
        <img src={Logo} className="img-fluid" height={100} width={200} alt='logo' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto py-2">
            {/* <NavLink to="/about" className='my-auto me-4 text-decoration-none navbar'>About</NavLink> */}
            <NavLink to="/about" className={({isActive}) =>
           `my-auto me-4 text-decoration-none navbar  ${isActive? "text-warning" : "text-info"}`
           
          }>About</NavLink>

            <NavLink to="/pricing" className={({isActive}) =>
           `my-auto me-4 text-decoration-none navbar  ${isActive? "text-warning" : "text-info"}`
           
          }>Pricing</NavLink>

            <NavLink to="/contact" className={({isActive}) =>
           `my-auto me-4 text-decoration-none navbar  ${isActive? "text-warning" : "text-info"}`
           
          }>Contact Us</NavLink>

            <NavLink to='/login' className={({isActive}) =>
           `my-auto me-4 text-decoration-none navbar  ${isActive? "text-warning" : "text-info"}`
           
          }>Sign In</NavLink>
          
            <NavLink to="/register">
              <Button className='rounded-3 px-5 btn btn-hero btn-lg'>Free trial</Button>
            </NavLink>
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;