import { Button } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import * as Icon from 'react-bootstrap-icons';

function Footer() {
  return (
    <>
    <Container className='mt-5'>
        <Row>
            <Col lg={3} className=''>
            <h5 className="mt-3 mb-4 ">Company</h5>
            <Nav className="flex-column">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/about">About Us</Nav.Link>
                
            </Nav>
            </Col>
            <Col lg={3} className=''>
            <h5 className="mt-3 mb-4 ">Quick Links</h5>
            <Nav className="flex-column">
                <Nav.Link href="privacy-policy">Privacy Policy</Nav.Link>
                <Nav.Link href="terms-condition">Terms and Condition</Nav.Link>
                <Nav.Link href="/">Help</Nav.Link>
                <Nav.Link href="/contact">Contact Us</Nav.Link>
            </Nav>
            </Col>
            <Col lg={5} className='mx-auto'>
                <img src="/images/logo.png" className="img-fluid" height={100} width={300} alt='logo' />
               
                <h5 className="fw-bold fs-5 mt-3">Subscribe to our Newsletter</h5>
                <Col lg={10} sm={6} className='rounded-4 p-2 bg-white mb-3 mt-4'>
                <InputGroup>
                  <Form.Control style={{ border: "hidden" }}
                    placeholder="Enter Your Email"
                    aria-label="Example text with button addon"
                    aria-describedby="basic-addon1"
                  />
                  <Button variant="btn btn-hero btn-md rounded-3" id="button-addon1">
                    Subscribe
                  </Button>
                </InputGroup>
                </Col>
            </Col>
        </Row>
        <Row>
            <Col lg={4} className='mt-5'>
                <hr/>
            </Col>
            <Col lg={4} className='mt-5 mx-auto text-center'>
                <p className='me-4'>
                    &copy;Copyright SmartQC 2023<span><Icon.Facebook className='mb-1 color-1 ms-3 bi-2x' /></span>
                    <span><Icon.Twitter className='mb-1 color-1 ms-3 bi-2x' /></span>
                    <span><Icon.Linkedin className='mb-1 color-1 ms-3 bi-2x' /></span>
                </p>
            </Col>
            <Col lg={4} className='mt-5'>
                <hr/>
            </Col>
        </Row>
    </Container>
    
    
    
    </>
  )
}

export default Footer