import { Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { getCookie } from "../../../utils/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import SubNav from "../../../Components/subNav";
import { useNavigate, Link } from "react-router-dom";

const Billing = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchData = () => {
      axios
        .get(`${baseURL}plan/getPlanList`, {
          headers: { ...headers },
        })
        .then((response) => {
          const Response = response.data;
          setPlans(Response);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.status === 401) {
            return navigate("/");
          }
        });
    };
    fetchData();
  }, []);

  const makePayment = async (_id, amount, planName, planDetails) => {
    const response = await fetch(`${baseURL}payment/createPayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        planId: _id,
        amount,
        currency: "inr",
      }),
    });
    const responseData = await response.json();
    if (response.ok) {
      // Check if response status is OK
      // console.log(responseData.paymentIntentResponse); // Access paymentIntentResponse
      navigate("/showresponce", {
        state: {
          paymentIntentResponse: responseData.paymentIntentResponse,
          planName: planName,
          planDetails: planDetails,
          amount: amount,
          currency: "usd",
        },
      });
    } else {
      // Handle error response
      console.error("Error:", responseData.error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" variant="info" />
        </div>
      ) : (
        <>
          <SubNav />
          <Container className="py-5">
            <Row>
              <Link
                to="/account/billing"
                className="fw-bold mb-3 text-decoration-none text-black"
              >
                Back to Billing
              </Link>
              <h5 className="pb-3">Plans</h5>
              <Col lg={12}>
                {plans &&
                  plans.data &&
                  plans.data.map((item) => (
                    <Col
                      lg={12}
                      className="border p-5 rounded-3 mb-3"
                      key={item.id}
                    >
                      <Row>
                        <Col>
                          <p className="fw-bold">{item.planName}</p>
                          <p>{item.planDetails}</p>
                        </Col>
                        <Col className="text-end">
                          <span className="fw-bold">${item.planPrice}</span>
                          <Button
                            variant="outline-secondary"
                            className="ms-3 btn-sm px-4"
                            onClick={() =>
                              makePayment(
                                item._id,
                                item.planPrice,
                                item.planName,
                                item.planDetails,
                           
                              )
                            }
                            type="button"
                          >
                            Change Plan
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  ))}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Billing;
