import { Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { getCookie } from "../../../utils/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import SubNav from "../../../Components/subNav";
import { useNavigate, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

const Billing = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchData = () => {
      axios
        .get(`${baseURL}plan/getPlanList`, {
          headers: { ...headers },
        })
        .then((response) => {
          const Response = response.data;
          setPlans(Response);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.status === 401) {
            return navigate("/");
          }
        });
    };
    fetchData();
  }, []);

  const makePayment = async (amount, planName, planDetails) => {
    const response = await fetch(`${baseURL}payment/createPayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({ amount }),
    });
    const responseData = await response.json();
    if (response.ok) {
      // Check if response status is OK
      console.log(responseData.paymentIntentResponse); // Access paymentIntentResponse
      navigate("/showresponce", {
        state: {
          paymentIntentResponse: responseData.paymentIntentResponse,
          planName: planName,
          planDetails: planDetails,
        },
      });
    } else {
      // Handle error response
      console.error("Error:", responseData.error);
    }
  };

  // const handlePaymentClick = async (amount) => {
  //   try {
  //     await makePayment(amount, bearerToken);

  //   } catch (error) {
  //     // Handle error
  //     console.error("Payment error:", error);
  //   }
  // };
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" variant="info" />
        </div>
      ) : (
        <>
          <SubNav />
          <Container className="py-5">
            <Link
              to="/account/billing"
              className="fw-bold mb-3 text-decoration-none text-black"
            >
              Back to Billing
            </Link>
            <h5 className="py-3">Invoice History</h5>
            <Row>
              <Col lg={12}>
                <Table hover>
                  <thead>
                    <tr>
                      <td className="text-black">DATE</td>
                      <td className="text-black">AMOUNT</td>
                      <td className="text-black">STATUS</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>February, 2024</td>
                      <td>999 USD</td>
                      <td>Paid</td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Billing;
// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { Container, Row, Col, Spinner, Table } from "react-bootstrap";
// import axios from "axios";
// import { baseURL } from "../../../utils/constant";
// import SubNav from "../../../Components/subNav";
// import { getCookie } from "../../../utils/auth";

// const Allinvoice = () => {
//   const navigate = useNavigate();
//   const [invoiceHistory, setInvoiceHistory] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [searchQuery, setSearchQuery] = useState("");
//   const bearerToken = getCookie("bearerToken");
//   const headers = {
//     Authorization: `Bearer ${bearerToken}`,
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       setIsLoading(true);
//       try {
//         // Clear previous search results
//         setInvoiceHistory([]);

//         const response = await axios.get(`${baseURL}invoice/history`, {
//           headers: { ...headers },
//           params: {
//             query: searchQuery // Pass search query as a parameter
//           }
//         });
//         setInvoiceHistory(response.data);
//         setIsLoading(false);
//       } catch (error) {
//         setIsLoading(false);
//         if (error.response && error.response.status === 401) {
//           navigate("/");
//         } else {
//           console.error("Error fetching invoice history:", error);
//         }
//       }
//     };

//     fetchData();
//   }, [searchQuery]); // Update when search query changes

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   return (
//     <>
//       {isLoading ? (
//         <div className="d-flex justify-content-center align-items-center pt-5">
//           <Spinner animation="border" variant="info" />
//         </div>
//       ) : (
//         <>
//           <SubNav />
//           <Container className="py-5">
//             <Link
//               to="/account/billing"
//               className="fw-bold mb-3 text-decoration-none text-black"
//             >
//               Back to Billing
//             </Link>
//             <h5 className="py-3">Invoice History</h5>
//             <Row>
//               <Col lg={12}>
//                 <input
//                   type="text"
//                   className="form-control mb-3"
//                   placeholder="Search..."
//                   value={searchQuery}
//                   onChange={handleSearchChange}
//                 />
//                 <Table hover>
//                   <thead>
//                     <tr>
//                       <th className="text-black">DATE</th>
//                       <th className="text-black">AMOUNT</th>
//                       <th className="text-black">STATUS</th>
//                       <th></th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {invoiceHistory.map((invoice) => (
//                       <tr key={invoice.id}>
//                         <td>{invoice.date}</td>
//                         <td>{invoice.amount}</td>
//                         <td>{invoice.status}</td>
//                         <td></td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </Table>
//               </Col>
//             </Row>
//           </Container>
//         </>
//       )}
//     </>
//   );
// };

// export default Allinvoice;
