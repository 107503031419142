import React from 'react'
// import Home from '../src/Components/Home/Home'
import Login from './views/Login/Login'

const App = () => {
  return (
    <div>
      <Login />
    </div>
  )
}

export default App