import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer/Footer";
import { Button } from "react-bootstrap";
import About_1 from "../../assets/img/about/about-1.png"
import About_2 from "../../assets/img/about/about-2.png";
import About_3 from "../../assets/img/about/about-3.png";
import About_5 from "../../assets/img/home/C-logo-5.png";

function About() {
  return (
    <>
      <Navbar />
      <Container className="py-5">
        <Col lg={7} className="mb-5 mx-auto text-center">
          <h1 className="mb-3 display-5 semi-bold">
            We are proud of our product
          </h1>
          <p className="">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, it to make a type specimen book.
          </p>
        </Col>
        <Row>
          <Col lg={4} className="mx-auto rounded-4 text-center">
            <img
              src={About_2}
              className="img-fluid mb-3"
              height={200}
              width={270}
              alt="Userboard"
            />
          </Col>
          <Col lg={4} className="mx-auto rounded-4 text-center">
            <img
              src={About_1}
              className="img-fluid mb-3"
              height={200}
              width={270}
              alt="Userboard"
            />
          </Col>
          <Col lg={4} className="mx-auto rounded-4 text-center">
            <img
              src={About_3}
              className="img-fluid mb-3"
              height={200}
              width={270}
              alt="Userboard"
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={6} className="mx-auto p-3">
            <h3 className="fw-bold fs-4 mb-3">Our Goals</h3>
            <Col lg={11} className="">
              <h2 className="fs-1 semi-bold mb-3">
                To upscale your business to the next level
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a{" "}
              </p>
            </Col>
          </Col>
          <Col lg={6} className="mx-auto p-3">
            <h3 className="fw-bold fs-4 mb-3">Our Vision</h3>
            <Col lg={11} className="">
              <h2 className="fs-1 semi-bold mb-3">
                To provide solutions for growing companies
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a{" "}
              </p>
            </Col>
          </Col>
          <Col lg={8} className="mx-auto text-center mt-5">
            <h2 className="display-6 semi-bold">Our corporate values</h2>
          </Col>
          <Col lg={12} className="mt-3 text-center">
            <img
              src={About_5}
              className="img-fluid mb-3"
              height={1200}
              width={1200}
              alt="Userboard"
            />
          </Col>
        </Row>
      </Container>
      <Container className="py-5 text-center">
        <Col lg={8} className="mx-auto mb-3">
          <h2 className="display-5 semi-bold mb-3">
            Ready to Elevate Your Lead Game?
          </h2>
          <p>
            Unlock the power of SmartQC today and experience the different for
            yourself. Choose
            <br /> your login option below and supercharge your lead quality
            assurance process.
          </p>
          <Button href="/contact" variant="btn btn-hero btn-lg rounded-3 mt-3">
            Contact Us
          </Button>
        </Col>
      </Container>
      <Footer />
    </>
  );
}

export default About;



