import { Row, Col } from "react-bootstrap";
// import { Button } from "react-bootstrap";
import { getCookie } from "../../utils/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../utils/constant";
import * as Icon from "react-bootstrap-icons";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';


const Dashboard = () => {
  const navigate = useNavigate();

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const [user, setUser] = useState({
    credits: 0,
    status: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${baseURL}user/getUserDetailsByUserId`, {
          headers: { ...headers },
        })
        .then((response) => {
          const { credits, status } = response.data.data;
          setUser({
            credits,
          });
          setIsLoading(false);
          if (status !== "Active") {
            setShowAlert(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.status === 401) {
            return navigate("/login");
          }
          setIsLoading(false);
        });
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row className="mb-3">
        <Col lg={2} className="ms-auto my-auto text-end">
          {isLoading ? (
            <div className="">
              <Spinner animation="grow" variant="warning" />
            </div>
          ) : (
            <h5 className="my-auto text-muted">
              <Icon.CashStack className="mb-1" /> {user.credits}
            </h5>
          )}
        </Col>

        <Col lg={2}>
        <div className="d-grid">
          <Link to='/account/campaigns/createCampaigns'>
          <Button variant="contained" endIcon={<AddIcon />}>
               New Campaign
            </Button>
          </Link>
            
          </div>
          </Col>
        <Col lg={1} className="">
          <div className="d-grid gap-2">
            <button className="btn btn-warning">
              <Icon.QuestionCircle className="mb-1" /> Help
            </button>
          </div>
        </Col>
      </Row>
      {showAlert && (
        <Row>
          <Col lg={12}>
            <Alert variant="warning" className="rounded-4">
              <h5>Instructions</h5>
              <p>
                Your email address is not verified{" "}
                <Link to="#">send verification link</Link>{" "}
              </p>
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Dashboard;
