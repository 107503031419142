
import React from 'react'
import { Container, Col } from 'react-bootstrap';
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer/Footer'

function terms() {
  return (
    <>
    
    <Navbar/>
    <Container className='py-5 px-lg-5 d-flex align-items-center'>
        <Col className='py-3'>
        <h1 class="fs-1">
            Terms and Conditions
            </h1>
            <p className="fs-3 ">Welcome to SmartQC!</p>
            <br/>
            <p>
               These terms and conditions outline the rules and regulations for the use of SmartQC, a B2B lead generation quality check tool that helps you improve the quality of your leads, prospects, campaigns, emails, and more.
            </p>
            <p>
              By accessing this website or using this tool, you accept these terms and conditions in full. Do not continue to use SmartQC if you do not agree to all of the terms and conditions stated on this page. 
            </p>
            <p>
               The following terminology applies to these terms and conditions, privacy statement and disclaimer notice, and any or all agreements: “you” and “your” refers to you, the person accessing this website or using this tool and accepting the company’s terms and conditions. “The company”, “Ourselves”, “we”, “our,” and “us” refers to our company. “Party”, “Parties”, or “us” refers to both the client and ourselves, or either the client or ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the client in the most appropriate manner, whether by formal meetings of a fixed duration or any other means, for the express purpose of meeting the client’s needs in respect of the provision of the company’s stated services/products, in accordance with and subject to, prevailing law of India. Any use of the above terminology or other words in the singular, plural, capitalization, and/or he/she or they are taken as interchangeable and, therefore, as referring to the same.
            </p>
            <h5 className="fs-3">License</h5>
            <p>
               Unless otherwise stated, SmartQC and/or its licensors own the intellectual property rights for all material on SmartQC. All intellectual property rights are reserved. You may view and/or print pages from www.smartqc.io for your own personal use subject to restrictions set in these terms and conditions.
            </p>
            <p>
               You must not:
            </p>
            <ul>
               <li>
                   Republish material from www.smartqc.io
               </li>
               <li>
                  Sell, rent, or sub-license material from www.smartqc.io
               </li>
               <li>
                  Reproduce, duplicate, or copy material from www.smartqc.io
               </li>
               <li>
                 Redistribute content from SmartQC (unless content is specifically made for redistribution).
               </li>
             </ul>
        </Col>
    </Container>
    <Footer/>
    
    
    
    
    
    </>
  )
}

export default terms