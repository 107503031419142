import Container from "react-bootstrap/Container";
import { Row, Col, Button, Spinner, Pagination } from "react-bootstrap";
import SubNav from "../../Components/subNav";
import LeadImage from "../../assets/img/dashbord/leadimport.jpg";
import Badge from "react-bootstrap/Badge";
import { getCookie } from "../../utils/auth";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { baseURL } from "../../utils/constant";
import { toast } from "react-toastify";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import * as Icon from 'react-bootstrap-icons';

const IdCampain = () => {
  const { _id } = useParams();

  const [IdData, setIdData] = useState({
    campaignName: "",
    status: "",
    recordCount: "",
  });
  const [Data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [selectedEntriesPerPage, setSelectedEntriesPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileInputDisabled, setFileInputDisabled] = useState(true);

  const [showImportButton, setShowImportButton] = useState(true);
  const [crossIconVisible, setCrossIconVisible] = useState(false);

  const navigate = useNavigate();

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const fetchData = () => {
    axios
      .get(`${baseURL}campaign/getCampaignByCampaignId/${_id}`, {
        headers: headers,
      })
      .then((response) => {
        // console.log(response);
        const { campaignName, status, recordCount, csvData } = response.data;
        setIdData({ campaignName, status, recordCount });
        setData(csvData);
        setIsLoading(false);
        const totalItems = csvData.totalItems || 0;
        setTotalPages(Math.ceil(totalItems / entriesPerPage));
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          // return navigate("/login");
        }
        setIsLoading(false);
        toast.error(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };



  // const handleSearch = (query) => {
  //   setSearchQuery(query);
  //   setCurrentPage(1); // Reset current page to 1 when searching

  //   // Call your API endpoint here
  //   axios.post(`${baseURL}your/search/api/endpoint`, { query }, { headers })
  //     .then((response) => {
  //       console.log("Search API Response:", response);
  //       // Handle response data as required
  //     })
  //     .catch((error) => {
  //       console.error("Search API Error:", error);
  //       // Handle error as required
  //     });
  // };



//reply in json format

  // const handleSearch = (query) => {
  //   setSearchQuery(query);
  //   setCurrentPage(1);
  //   // Prepare payload
  //   const payload = {
  //     query: query
  //     // Add any other parameters you need in the payload
  //   };
  //   // Call your API endpoint here
  //   axios.post(`${baseURL}your/search/api/endpoint`, payload, { headers })
  //     .then((response) => {
  //       // Update Data state with response data
  //       setData(response.data);
  //       setIsLoading(false);
  //       const totalItems = response.data.totalItems || 0;
  //       setTotalPages(Math.ceil(totalItems / entriesPerPage));
  //     })
  //     .catch((error) => {
  //       console.error("Search API Error:", error);
  //       // Handle error as required
  //     });
  // };



  // const handleSearch = (query) => {
  //   setSearchQuery(query);
  //   setCurrentPage(1);
  //   // Call your API endpoint here
  //   axios.get(`${baseURL}your/search/api/endpoint`, { params: { query }, headers })
  //     .then((response) => {
  //       // Update Data state with response data
  //       setData(response.data);
  //       setIsLoading(false);
  //       const totalItems = response.data.totalItems || 0;
  //       setTotalPages(Math.ceil(totalItems / entriesPerPage));
  //     })
  //     .catch((error) => {
  //       console.error("Search API Error:", error);
  //       // Handle error as required
  //     });
  // };


  const handleEntriesPerPageChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    setSelectedEntriesPerPage(selectedValue);
    setEntriesPerPage(selectedValue);
    setCurrentPage(1);
  };

  const filteredData = Data.filter((item) => {
    const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });

  const itemsPerPage = 5;

  const startIndex = (currentPage - 1) * entriesPerPage;
  const endIndex = startIndex + entriesPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFileInputDisabled(false);
  }, [Data]);

  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setShowImportButton(false);
    setCrossIconVisible(true);
  };

  const handleImportClick = async () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async () => {
    try {
      if (!selectedFile) {
        toast.error("Please select a file for import", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

      console.log("Selected File:", selectedFile.name);

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("campaignId", _id);
      const response = await axios.post(
        `${baseURL}campaign/importLeads`,
        formData,
        { headers: { ...headers } }
      );

      if (response.status === 200) {
        // setFile(null);
        toast.success("Campaigns Uploaded Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        window.location.reload();
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        return navigate("/login");
      }
    }
  };

  const DownloadFile = () => {
    axios
      .get(`${baseURL}campaign/dowloadSampleImportFileByCampaignId/${_id}`, {
        headers: { ...headers },
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers["content-disposition"];
        const filenameMatch =
          contentDisposition && contentDisposition.match(/filename=(.+)$/);
        const filename = filenameMatch
          ? filenameMatch[1]
          : `sample_import_${_id}.csv`;

        link.setAttribute("download", filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast.success("File downloaded successfully", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message || "Failed to download file", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData.length / entriesPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <Pagination>
        {pageNumbers.map((number) => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
            className="bg-white"
          >
            {number}
          </Pagination.Item>
        ))}
      </Pagination>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" variant="info" />
        </div>
      ) : (
        <>
          <SubNav />
          <Container className="py-5">
            <Row>
              <Col lg={12} className="mx-auto">
                <div className="mb-3">
                  <Link
                    to="/account/dashboard"
                    className="text-decoration-none"
                  >
                    Back to Dashboard
                  </Link>
                </div>
                <h3>{IdData.campaignName}</h3>

                <Badge
                  className={
                    IdData.status === "Pending" ? "bg-warning" : "bg-success"
                  }
                >
                  {IdData.status === "Pending" ? "In Progress" : IdData.status}
                </Badge>
                <span className="m-auto justify-content-end d-flex">
                  {IdData.recordCount} Contacts
                </span>
                <hr className="mb-4" />
              </Col>
            </Row>

            <Row>
              <Col lg={12} className="mx-auto">
                {Data.length === 0 ? (
                  <Col
                    lg={12}
                    className="mx-auto p-5 rounded-3 text-center text-black"
                  >
                    <Alert className="text-center" variant="warning">
                      Note: Please take a look at the csv file format{" "}
                      <a
                        className="text-decoration-none cursor-pointer"
                        onClick={DownloadFile}
                      >
                        here
                      </a>{" "}
                      before importing the contacts.{" "}
                    </Alert>
                    {/* <h3 className="mb-5">
                      The leads playground is currently a solo act. How about
                      bringing some to the lead pool?
                    </h3> */}
                    {showImportButton && (
                      <>
                        <Button
                          className="mb-4"
                          variant="btn btn-blue"
                          onClick={handleImportClick}
                          disabled={fileInputDisabled}
                        >
                          Import .csv file
                        </Button>

                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </>
                    )}

                    {showImportButton && (
                      <>
                        <Button
                          className="mb-4"
                          variant="btn btn-blue"
                          onClick={handleImportClick}
                          disabled={fileInputDisabled}
                          style={{ display: "none" }}
                        >
                          Submit
                        </Button>

                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </>
                    )}

                    {crossIconVisible && (
                      <>
                        <p
                          className="mb-4 text-danger cursor-pointer"
                          onClick={() => {
                            setShowImportButton(true);
                            setCrossIconVisible(false);
                            setSelectedFile(null);
                          }}
                        >
                          Selected File: {selectedFile.name} &#10006;
                        </p>
                        <span className="mb-4"></span>
                        <Button
                          className="mb-4"
                          variant="btn btn-blue"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </>
                    )}
                    <div></div>
                    {/* <img
                      src={LeadImage}
                      width={500}
                      alt="LeadImage"
                      className="d-flex m-auto"
                    /> */}
                  </Col>
                ) : (
                  <>
                    <Row className="me-auto">
                      <Col lg={3}>
                        <h5>Your contacts</h5>
                      </Col>
                      <Col lg={3} className="ms-auto">
                        <Form.Control
                          type="text"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                      </Col>
                      <Col lg={1}>
                        <div className="mb-3">
                          <Form.Select
                            value={selectedEntriesPerPage}
                            onChange={handleEntriesPerPageChange}
                          >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                          </Form.Select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                    {currentData.map((item, index) => (
                      
                          <Col lg={4} key={index}>
                            <Col lg={12} className="border py-3 px-4 rounded mb-3">
                                <p className="mb-0" style={{fontSize: '18px'}}>
                                  {item.firstName} {item.lastName}
                                </p>
                                <p className="mb-0 fw-bold" style={{fontSize: '14px'}}>
                                  {item.jobTitle}
                                </p>
                                <p className="mb-0" style={{fontSize: '14px'}}>
                                  <Icon.EnvelopeFill /> {item.email} 
                                </p>
                                <p className="mb-0" style={{fontSize: '14px'}}>
                                  <Icon.TelephoneFill /> {item.phoneNumber} 
                                </p>
                                <p className="mb-0" style={{fontSize: '14px'}}>
                                  <Icon.PostcardFill /> {item.companyName}
                                </p>
                                <p className="mb-0" style={{fontSize: '14px'}}>
                                  <Badge bg="primary" className="rounded-0">{item.status}</Badge>
                                </p>
                                
                            </Col>
                          </Col>
                      
                    ))}
                    </Row>
                    {renderPagination()}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default IdCampain;
