import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import App from "./App";
import Login from "./views/Login/Login.jsx";
import ForgotPassword from "./views/Login/forgotPassword/forgotPassword.jsx";
import Register from "./views/Register/Register";
// import Verify from "./views/Register/Verify/Verify.jsx";
import Dashboard from "./views/account/Dashboard/Dashboard.jsx";
import Campaigns from "./views/account/Campaigns/Campaigns.jsx";
import UplaodCampaigns from "./views/account/Campaigns/UplaodCampaigns/UplaodCampaigns.jsx";
import ImportLeads from "./views/CampaignId/ImportLeads.jsx";
import CreateCampaigns from "./views/CreateCampaign/createCampaign.jsx";
import Profile from "./views/account/Profile/Profile.jsx";
import Billing from "./views/account/Billing/Billing.jsx";
import Plans from "./views/account/Billing/Plans.jsx";
import About from "./views/About/About.jsx";
import Contact from "./views/Contact/Contact.jsx";
import PrivacyPolicy from "./views/Privacy-Policy/privacy-policy.jsx";
import TermsCondition from "./views/Terms-Condition/terms-condition.jsx";
import Pricing from "./views/Pricing/Pricing.jsx";
import CampaignId from "./views/CampaignId/CampaignId.jsx";
import CampaignType from "./views/CreateCampaign/CampaginTyle.jsx";
// import StripeForm from "./views/Stripe/Stripe.js";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import NewCampaignType from "./views/CreateCampaign/NewCampaignType.jsx";
import ShowResponce from "./views/account/Billing/ShowResponce.jsx";
// import Stripe from "./views/account/Billing/Stripe.jsx";
import Thanks from "./views/Thanks.jsx";
import Allinvoice from "./views/account/Billing/Allinvoice.jsx";
import Razorpay from "./views/account/Billing/Razorpay.jsx";
import './index.css'

const stripePromise = loadStripe("your_publishable_key");

const PrivateRoute = ({ isAuthenticated, ...props }) => {
  const token = localStorage.getItem("token");
  return isAuthenticated && token ? (
    <Outlet />
  ) : (
    <Navigate replace to="/login" />
  );
};

const AppRoutes = () => {
  const [isAuthenticated, setisAuthenticated] = useState(() => {
    const token = localStorage.getItem("token");
    return !!token;
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    setisAuthenticated(!!token);
  }, []);

  

  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<App />} />

        <Route
          path="/login"
          element={<Login setisAuthenticated={setisAuthenticated} />}
        />

        <Route
          path="/register"
          element={<Register setisAuthenticated={setisAuthenticated} />}
        />

        <Route
          path="/account/dashboard"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/dashboard" element={<Dashboard />} />
        </Route>

        <Route
          path="/account/campaigns"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/campaigns" element={<Campaigns />} />
        </Route>

        <Route
          path="/account/profile"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/profile" element={<Profile />} />
        </Route>

        <Route
          path="/account/billing/invoices"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/billing/invoices" element={<Allinvoice />} />
        </Route>

        <Route
          path="/account/billing"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/billing" element={<Billing />} />
        </Route>

        <Route
          path="/account/billing/plans"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/account/billing/plans" element={<Plans />} />
        </Route>

        <Route
          path="/account/campaigns/createCampaigns"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route
            path="/account/campaigns/createCampaigns"
            element={<UplaodCampaigns />}
          />
        </Route>

        <Route
          path="/createcampaign"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="" element={<CreateCampaigns />} />
        </Route>

        <Route
          path="/createcampagin/create-type"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route
            path="/createcampagin/create-type"
            element={<CampaignType />}
          />
        </Route>
        <Route
          path="/createcampagin/newcreate-type"
          element={<NewCampaignType />}
        />

        <Route
          path="/campaignid/:_id"
          element={<PrivateRoute isAuthenticated={isAuthenticated} />}
        >
          <Route path="/campaignid/:_id" element={<CampaignId />} />
        </Route>

        <Route path="/campaignid/:_id/importLeads" element={<ImportLeads />} />

        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        {/* <Route path="/register/verify" element={<Verify />} /> */}
        <Route path="/about" element={<About />} />

        {/* <Route exact path='/stripe' element={<StripeForm />} /> */}

        {/* <Route path="/return" element={<Return />} /> */}

        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/stripe" element={<StripeForm />} /> */}
        {/* <Route path="/stripe" element={<Stripe />} /> */}
        <Route path="/showresponce" element={<ShowResponce />} />
        <Route path="/razorpay" element={<Razorpay />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/thanks" element={<Thanks />} />
        {/* <Route path="/allinvoice" element={<Allinvoice />} /> */}
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <Elements stripe={stripePromise}>
    <AppRoutes />
  </Elements>
);
