import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
// import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { logOut } from "../utils/auth";
import cookie from "js-cookie";
import { getCookie } from "../utils/auth";
import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import { baseURL } from "../utils/constant";
import Logo from "../assets/img/dashbord/companylogo.png";

const Dashboard = () => {
  const navigate = useNavigate();

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const [user, setUser] = useState({
    credits: 0,
    status: "",
    firstName: "",
    lastName: "",
  });
  const [ShowAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleLogOut = () => {
    const bearerToken = cookie.get("bearerToken");
    logOut(bearerToken);
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${baseURL}user/getUserDetailsByUserId`, {
          headers: { ...headers },
        })
        .then((response) => {
          // console.log(response);
          const { credits, status, firstName, lastName } = response.data.data;
          setUser({
            credits,
            firstName,
            lastName,
          });
          setIsLoading(false);
          if (status !== "Active") {
            setShowAlert(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response && err.response.status === 401) {
            return navigate("/");
          }
          setIsLoading(false);
        });
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    axios
      .get(`${baseURL}auth/sendVerificationLink`, {
        headers: { ...headers },
      })
      .then((response) => {
        console.log(response);
        toast.success("Verification link sent on email", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.success(err.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <>
      {/* {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" variant="info" />
        </div>
      ) : ( */}
        <div className="Container-fluid">
          <Navbar expand="lg" className="bg-white border-bottom ">
            <Container>
              <Navbar.Brand>
                <img src={Logo} width={150} alt="Logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Link
                    to="/account/dashboard"
                    className="text-decoration-none mx-3 text-black"
                  >
                    Dashboard
                  </Link>
                </Nav>
                <Nav className="ms-auto">
                  {/* <Nav.Link href="#home"></Nav.Link>
                  <Nav.Link className="my-auto fs-6">
                    {user.credits} credits
                  </Nav.Link> */}

                  {/* <Button
                    variant="btn btn-blue"
                    className="me-4"
                    onClick={() => navigate("/createcampagin/create-type")}
                  >
                    New Campaign +
                  </Button> */}

                  <NavDropdown
                    title={`${user.firstName} ${user.lastName}`}
                    id="basic-nav-dropdown"
                    className="my-auto"
                  >
                    <NavDropdown.Item
                      onClick={() => navigate("/account/profile")}
                    >
                      Account Settings
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => navigate("/account/billing")}
                    >
                      Billing
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={handleLogOut}>
                      Sign Out
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          {ShowAlert && (
            <Container className="my-3">
            <Alert variant="warning">
              
                <Row>
                <p className="mb-0">
                  Your email address is not verified. To verify your account,
                  please find the link in your inbox or{" "}
                  <a href="#" onClick={getData}>
                    click here
                  </a>{" "}
                  to resend verification link
                </p>
                </Row>
              
            </Alert>
            </Container>
          )}
        </div>
      {/* )} */}
    </>
  );
};

export default Dashboard;
