// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import { Container, Row, Col } from "react-bootstrap";
// import { toast } from "react-toastify";
// import { Link, useNavigate } from "react-router-dom";
// import { baseURL } from "../../utils/constant";
// import React, { useState, useEffect } from "react";
// import Logo from "../../assets/img/dashbord/companylogo.png";
// import LoginImage from "../../assets/img/login&sign-up/login.jpg";
// import { isLogin, setAuthentication } from "../../utils/auth";
// import axios from "axios";

// // console.log(baseURL);

// export default function Login({ setisAuthenticated }) {
//   const navigate = useNavigate();

//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");

//   useEffect(() => {
//     const authenticate = async () => {
//       if (await isLogin()) {
//         navigate("/account/dashboard");
//       }
//     };
//     authenticate();
//   }, [navigate]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "email") {
//       setEmail(value);
//     } else if (name === "password") {
//       setPassword(value);
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const payload = {
//       email,
//       password,
//     };

//     axios
//       .post(`${baseURL}auth/login`, payload)
//       .then((res) => {
//         console.log(res);
//         setAuthentication(res.data.bearerToken);
//         localStorage.setItem("token", res.data.bearerToken);
//         navigate("/account/dashboard");
        
//         setisAuthenticated(true);
//         toast.success("Login Successful", {
//           position: "bottom-left",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//       })
//       .catch((err) => {
//         toast.error(err?.response?.data?.message, {
//           position: "bottom-left",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//       });
//   };

//   return (
//     <>
//       <div className="d-flex align-items-center bg-login bg-white">
//         <Container fluid className="">
//           <Row>
//             {/* <Col lg={8} className="d-flex align-items-center">
//               <Col lg={8} className=" mx-auto">
//               <img src={Logo} alt="logo" className="img-fluid " />
//               </Col>
//             </Col> */}
//             <Col lg={4} className=" bg-white vh-100 d-flex align-items-center mx-auto">
//               <Col lg={10} className="p-5 bg-white shadow rounded-3 mx-auto">
//                 {/* <Link to="https://smartqc.io">
//                   <img
//                     src={Logo}
//                     width={150}
//                     alt="logo"
//                     className="mb-3 d-flex m-auto"
//                   />
//                 </Link> */}
                
//                 <h5 className="mb-4 text-center">Sign in</h5>
//                 <Form onSubmit={handleSubmit}>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Email address</Form.Label>
//                     <Form.Control
//                       type="email"
//                       className="rounded-3 form-control-sm"
//                       name="email"
//                       onChange={handleInputChange}
//                     />
//                   </Form.Group>

//                   <Form.Group className="mb-2">
//                     <Form.Label>Password</Form.Label>
//                     <Form.Control
//                       type="password"
//                       className="rounded-3 form-control-sm"
//                       name="password"
//                       onChange={handleInputChange}
//                     />
//                   </Form.Group>
//                   <div className="d-flex justify-content-end mb-3">
//                   <Link className="Forgotlink" to="forgotpassword">
//                     Forgot password?
//                   </Link>
//                   </div>
//                   <div className="d-grid gap-2 mb-4">
//                     <Button
//                       variant="btn btn-blue"
//                       type="submit"
//                       className="rounded-3"
//                     >
//                       Log in
//                     </Button>
//                   </div>
//                 </Form>
//                 <p className="text-center">
//                   Don't have an account?{" "}
//                   <Link to="/register" className="text-decoration-none">
//                     Sign up
//                   </Link>
//                 </p>
//               </Col>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }


import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../utils/constant";
import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/dashbord/companylogo.png";
import LoginImage from "../../assets/img/login&sign-up/login.jpg";
import { isLogin, setAuthentication } from "../../utils/auth";
import axios from "axios";

export default function Login({ setisAuthenticated }) {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authenticate = async () => {
      if (await isLogin()) {
        navigate("/account/dashboard");
      }
    };
    authenticate();
  }, [navigate]);

  useEffect(() => {
    const checkAuthentication = async () => {
      if (await isLogin()) {
        setIsAuthenticated(true);
      }
    };
    checkAuthentication();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      email,
      password,
    };

    axios
      .post(`${baseURL}auth/login`, payload)
      .then((res) => {
        setAuthentication(res.data.bearerToken);
        localStorage.setItem("token", res.data.bearerToken); // Save token in localStorage
        navigate("/account/dashboard");
        setisAuthenticated(true);
        toast.success("Login Successful", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <>
      <div className="d-flex align-items-center bg-login bg-white">
        <Container fluid className="">
          <Row>
            <Col lg={4} className=" bg-white vh-100 d-flex align-items-center mx-auto">
              <Col lg={10} className="p-5 bg-white shadow rounded-3 mx-auto">
                <h5 className="mb-4 text-center">Sign in</h5>
                {isAuthenticated ? (
                  <p className="text-center">You are already authenticated.</p>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        className="rounded-3 form-control-sm"
                        name="email"
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        className="rounded-3 form-control-sm"
                        name="password"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <div className="d-flex justify-content-end mb-3">
                      <Link className="Forgotlink" to="forgotpassword">
                        Forgot password?
                      </Link>
                    </div>
                    <div className="d-grid gap-2 mb-4">
                      <Button
                        variant="btn btn-blue"
                        type="submit"
                        className="rounded-3"
                      >
                        Log in
                      </Button>
                    </div>
                  </Form>
                )}
                <p className="text-center">
                  Don't have an account?{" "}
                  <Link to="/register" className="text-decoration-none">
                    Sign up
                  </Link>
                </p>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
