import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { Button, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { getCookie } from "../../../utils/auth";
import { baseURL } from "../../../utils/constant";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import AccountNav from "../../../Components/AccountNav/AccountNav";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const Campaigns = () => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("campaignName");
  const [order, setOrder] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const payload = {
    page: "1",
    start: "0",
    length: `${rows.recordsTotal}`,
    columns: [
      {
        data: "campaignName",
        name: "",
        searchable: true,
        orderable: true,
        search: {
          value: "",
          regex: false,
        },
      },
      {
        data: "recordCount",
        name: "",
        searchable: true,
        orderable: true,
        search: {
          value: "",
          regex: false,
        },
      },
      {
        data: "userId",
        name: "",
        searchable: true,
        orderable: true,
        search: {
          value: "",
          regex: false,
        },
      },
    ],
    order: [
      {
        column: 0,
        dir: "asc",
      },
    ],
    search: {
      value: "",
      regex: false,
    },
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const fetchData = () => {
    axios
      .post(`${baseURL}campaign/getDataTableForCampaignByUserId`, payload, {
        headers: { ...headers },
      })
      .then((response) => {
        const Response = response.data;
        setRows(
          Response.data.map((item) => ({
            ...item,
            isNew: item.isNew !== undefined ? item.isNew : false,
          }))
        );
        setIsLoading(false);
        console.log(Response);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response && err.response.status === 400) {
          return navigate("/login");
        }
        toast.error(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("Rows:", rows);
  }, [rows]);

  const filterData = (v) => {
    if (v) {
      const filteredRows = rows.filter((row) =>
        row.campaignName.toLowerCase().includes(v.toLowerCase())
      );
      setRows(filteredRows);
    } else {
      fetchData();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
    console.log(searchValue);
  };


  const renderCampaignName = (row) => {
    const isNewCampaign = row.isNew || false;
    console.log("isNew:", row.isNew);
  
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {isNewCampaign && (
          <span className="badge bg-success me-2">New Campaign</span>
        )}
        {row.campaignName}
      </div>
    );
  };
 
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Divider />
        <Col lg={11} className="rounded-4 py-5" style={{ marginLeft: "2rem" }}>
          <AccountNav />
          <Row>
            <Col lg={12}>
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center pt-5">
                  <Spinner animation="grow" variant="warning" />
                </div>
              ) : (
                <Paper
                  sx={{ width: "100%", overflow: "hidden", padding: "12px" }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ padding: "20px" }}
                  >
                    Campaigns List
                  </Typography>
                  <Divider />

                  <Box height={10} />
                  <Stack direction="row" spacing={2} className="my-2 mb-2">
                    <TextField
                      size="small"
                      label="Search"
                      value={searchValue}
                      onChange={handleSearchChange}
                    />

                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    ></Typography>
                  </Stack>
                  <Box height={10} />
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" style={{ minWidth: "100px" }}>
                            <TableSortLabel
                              active={orderBy === "campaignName"}
                              direction={
                                orderBy === "campaignName" ? order : "asc"
                              }
                              onClick={() => handleSort("campaignName")}
                            >
                              Campaign Name
                            </TableSortLabel>
                          </TableCell>

                          {/* <TableCell align="left" style={{ minWidth: "100px" }}>
                            <TableSortLabel
                              active={orderBy === "campaignName"}
                              direction={
                                orderBy === "campaignName" ? order : "asc"
                              }
                              onClick={() => handleSort("campaignName")}
                            >
                               Name
                            </TableSortLabel>
                          </TableCell> */}

                          <TableCell align="left" style={{ minWidth: "100px" }}>
                            <TableSortLabel
                              active={orderBy === "recordCount"}
                              direction={
                                orderBy === "recordCount" ? order : "asc"
                              }
                              onClick={() => handleSort("recordCount")}
                            >
                              Record Count
                            </TableSortLabel>
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "100px" }}>
                            <TableSortLabel
                              active={orderBy === "status"}
                              direction={orderBy === "status" ? order : "asc"}
                              onClick={() => handleSort("status")}
                            >
                              Status
                            </TableSortLabel>
                          </TableCell>

                          <TableCell align="left" style={{ minWidth: "100px" }}>
                            ID
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          .slice()
                          .sort((a, b) => {
                            const isAsc = order === "asc";

                            return isAsc
                              ? a[orderBy].localeCompare(b[orderBy])
                              : b[orderBy].localeCompare(a[orderBy]);
                          })
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                <TableCell align="left">
                                {renderCampaignName(row)}
                                </TableCell>

                                {/* <TableCell align="left">
                                {row.user.firstName} {row.user.lastName}
                                </TableCell> */}

                                <TableCell align="left">
                                  {row.recordCount}
                                </TableCell>

                                <TableCell align="left">{row.status}</TableCell>

                                <TableCell align="left">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "1rem",
                                    }}
                                  >
                                    <Link
                                      to={`/campaignid/${row._id}`}
                                      className="mb-3 text-decoration-none"
                                    >
                                      <Button
                                        variant="warning"
                                        size="sm"
                                        className="rounded-3"
                                      >
                                        <Icon.Eye className="" />
                                      </Button>
                                    </Link>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 12, 13, 25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )}
            </Col>
          </Row>
        </Col>
      </Box>
    </Box>
  );
};

export default Campaigns;
