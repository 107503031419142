import { Container, Row, Col } from 'react-bootstrap';
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer/Footer'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function Contact() {
  return (
    <>
    <Navbar/>
    <Container className='py-5'>
        <Col lg={11} sm={12} className='mx-auto py-4 text-center'>
            <h1 className="mb-5 display-5 semi-bold">
              Get in touch with us 
            </h1>  
        </Col>
        <Col lg={11} className='py-3 bg-yellow p-3 rounded-4 mx-auto' >
        <Row>
            <Col lg={7} className='py-3 mx-auto'>
                    <Col lg={10} className='py-2 mx-auto'>
                        <h2 className="display-6 semi-bold mb-3 text-white">Drop a message</h2>
                        <p className='text-white mb-5'>
                            we will get back to you as soon as possible.
                        </p>
                        <Form>
                            <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" placeholder="Full Name" className='' name="fullName"/>
                                </Form.Group>
                                </Col>
                                <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" placeholder="Company Name" className='' name="companyName"/>
                                </Form.Group>
                                </Col>
                            
                            </Row>
                                <Form.Group className="mb-3"   controlId="exampleForm.ControlInput1">
                                    <Form.Control type="email" name="email" placeholder="Email address" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control type="text" placeholder="Subject" className='' name="subject"/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" placeholder="message..." rows={3} />
                                </Form.Group>
                                <div className="d-grid gap-2">
                                <Button type="submit" variant="btn btn-hero btn-lg rounded-3 mt-3">
                                    Send
                                </Button>
                                </div>
                        </Form>
                    </Col>
                
            </Col>
            <Col lg={5} className='py-3 mx-auto'>

            </Col>
        </Row>
        </Col>
    </Container>
    <Container className='py-5 text-center'>
      <Col lg={8} className='mx-auto mb-3'>
        <h2 className="display-5 semi-bold mb-3">Ready to Elevate Your Lead Game?</h2>
        <p>
          Unlock the power of SmartQC today and experience the different for yourself. Choose<br/> your login option below and supercharge your lead quality assurance process.
        </p>
          <Button variant="btn btn-hero btn-lg rounded-3 mt-3">
            Contact Us
          </Button>
      </Col>
    </Container>
    <Footer/>
    
    
    
    
    
    
    </>
  )
}

export default Contact