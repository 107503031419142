import { getCookie } from "../../../utils/auth";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Spinner } from "react-bootstrap";
import SubNav from "../../../Components/subNav";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";

const Profile = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({ firstName: "", lastName: "", email: "" });
  const [securityPassword, setsecurityPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${baseURL}user/getUserDetailsByUserId`, {
          headers: { ...headers },
        })
        .then((response) => {
          const { firstName, lastName, email } = response.data.data;
          setUser({
            firstName,
            lastName,
            email,
          });
          console.log(user);
          console.log(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            return navigate("/login");
          }
          setIsLoading(false);
          console.error(err?.response?.data?.message);
        });
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" variant="info" />
        </div>
      ) : (
        <>
          <SubNav />
          <Container className="py-5">
            <div className="row">
              <h3 className="fw-bold mb-5">Account Settings</h3>
         
              {/* <Col lg={2} className="">
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link href="/home" className="ps-0 text-black fw-bold">
                  Profile
                </Nav.Link>
                <Nav.Link eventKey="link-1" className="ps-0 text-black fw-bold">
                  Security
                </Nav.Link>
                <Nav.Link eventKey="link-2" className="ps-0 text-black fw-bold">
                  Notifications
                </Nav.Link>
              </Nav>
            </Col> */}

              <Col lg={2}>
                <div
                  id="simple-list-example"
                  className="d-flex flex-column gap-2 simple-list-example-scrollspy text-start sticky-top"
                >
                  <a
                    className="p-1 rounded text-decoration-none text-dark fw-bold"
                    href="#profile"
                  >
                    Profile
                  </a>
                  <a
                    className="p-1 rounded text-decoration-none text-dark fw-bold"
                    href="#security"
                  >
                    Security
                  </a>
                  <a
                    className="p-1 rounded text-decoration-none text-dark fw-bold"
                    href="#notification"
                  >
                    Notifications
                  </a>
                </div>
              </Col>

              <Col lg={10}>
                <div
                  data-bs-spy="scroll"
                  data-bs-target="#simple-list-example"
                  data-bs-offset="0"
                  data-bs-smooth-scroll="true"
                  className="scrollspy-example"
                  tabindex="0"
                >
                  <div className="border p-5 rounded-3 mb-3">
                    <h5 id="profile">Profile</h5>
                    <br />
                    <Row>
                      <Col>
                        <p>CONTACT EMAIL</p>
                      </Col>
                      <Col>
                        <p>{user.email}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>FULL NAME</p>
                      </Col>
                      <Col>
                        <p>
                          {`${user.firstName} ${user.lastName}`}{" "}
                          <Button
                            variant="outline-secondary"
                            className="ms-2 btn-sm"
                          >
                            Edit
                          </Button>
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <div className="border p-5 rounded-3 mb-3">
                    <h5 id="security">Security</h5>
                    <br />

                    <Row>
                      <Col>
                        <p>Password</p>
                      </Col>
                      <Col className="text-end">
                        <Button
                          variant="outline-secondary"
                          className="ms-2 btn-sm"
                        >
                          Change Password
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="border p-5 rounded-3 mb-3">
                    <h5 id="notification">Notifications</h5>
                    <br />
                    <Row>
                      <Col>
                        <p>Default Campaign Notifications</p>
                        <p>
                          Set default notifications for campaigns. This setting
                          can be overridden per campaign.
                        </p>
                      </Col>
                      <Col className="text-end">
                        <Form.Select
                          aria-label="Default select example"
                          disabled
                        >
                          <option selected>All notifications</option>
                        </Form.Select>
                        <Button
                          variant="outline-secondary"
                          className="ms-2 btn-sm mt-3"
                        >
                          Edit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              
              {/* <Col lg={1}></Col>
              <Col lg={9} className="ms-5">
                <Col lg={12} className="border p-5 rounded-3 mb-3">
                  <h5 className="border-bottom pb-3">Profile</h5>
                  <br />
                  <Row>
                    <Col>
                      <p>CONTACT EMAIL</p>
                    </Col>
                    <Col>
                      <p>{user.email}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>FULL NAME</p>
                    </Col>
                    <Col>
                      <p>
                        {`${user.firstName} ${user.lastName}`}{" "}
                        <Button
                          variant="outline-secondary"
                          className="ms-2 btn-sm"
                        >
                          Edit
                        </Button>
                      </p>
                    </Col>
                  </Row>
                </Col>

                <Col lg={12} className="border p-5 rounded-3 mb-3">
                  <h5 className="border-bottom pb-3">Security</h5>
                  <br />

                  <Row>
                    <Col>
                      <p>Password</p>
                    </Col>
                    <Col className="text-end">
                      <Button
                        variant="outline-secondary"
                        className="ms-2 btn-sm"
                      >
                        Change Password
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} className="border p-5 rounded-3">
                  <h5 className="border-bottom pb-3">Notifications</h5>
                  <br />

                  <Row>
                    <Col>
                      <p>Default Campaign Notifications</p>
                      <p>
                        Set default notifications for campaigns. This setting
                        can be overridden per campaign.
                      </p>
                    </Col>
                    <Col className="text-end">
                      <Form.Select aria-label="Default select example" disabled>
                        <option selected>All notifications</option>
                      </Form.Select>
                      <Button
                        variant="outline-secondary"
                        className="ms-2 btn-sm mt-3"
                      >
                        Edit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Col> */}
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default Profile;
