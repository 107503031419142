import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../utils/auth";
import { baseURL } from "../../../utils/constant";
import axios from "axios";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import { Row, Col, Button, ProgressBar, Badge } from "react-bootstrap";
import DashboardImage from "../../../assets/img/dashbord/dashboard.jpg";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Nav from "react-bootstrap/Nav";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import SubNav from "../../../Components/subNav";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const formatDate = (dateString) => {
  const currentDate = new Date();
  const createdAtDate = new Date(dateString);
  const timeDifference = currentDate - createdAtDate;
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (daysAgo === 0) {
    return "Today";
  } else if (daysAgo === 1) {
    return "Yesterday";
  } else {
    return `${daysAgo} days ago`;
  }
};
const debounceTime = 300;

const Dashboard = () => {
  const [sortOrder, setSortOrder] = useState("desc");
  const [campaignData, setCampaignData] = useState({
    data: [],
    recordsTotal: 0,
  });
  const [isEmpty, setIsEmpty] = useState({
    isEmpty: "",
  });
  const [filteredData, setFilteredData] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [debouncedSearchTimer, setDebouncedSearchTimer] = useState(null);
  const [newPayload, setNewPayload] = useState({
    page: "1",
    start: "0",
    length: `${campaignData.recordsTotal}`,
    columns: [
      { data: "campaignName", name: "", searchable: true, orderable: true },
      { data: "recordCount", name: "", searchable: true, orderable: true },
      { data: "userId", name: "", searchable: true, orderable: true },
    ],
    order: [{ column: 0, dir: "asc" }],
    search: { value: searchTerm, regex: false },
  });

  const navigate = useNavigate();

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const handleSearch = async (e) => {
    try {
      const searchTermValue = e.target.value;
      setSearchTerm(searchTermValue);
      setCurrentPage(1);

      clearTimeout(debouncedSearchTimer);
      setDebouncedSearchTimer(
        setTimeout(async () => {
          try {
            const sortColumn = 0;
            const sortDirection = sortOrder === "asc" ? "asc" : "desc";

            const updatedPayload = {
              ...newPayload,
              search: {
                value: searchTermValue,
                regex: false,
              },
              order: [
                {
                  column: sortColumn,
                  dir: sortDirection,
                },
              ],
            };
            console.log("Updated Payload:", updatedPayload);

            const response = await axios.post(
              `${baseURL}campaign/getDataTableForCampaignByUserId`,
              updatedPayload,
              { headers }
            );

            const responseData = response.data;
            console.log("API Response Data:", responseData);
            setCampaignData(responseData);
            setFilteredData(responseData.data);
          } catch (err) {
            console.error("API Request Error:", err);
          } finally {
            setIsLoading(false);
          }
        }, debounceTime)
      );
    } catch (error) {
      console.error("Handle Search Error:", error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseURL}campaign/getDataTableForCampaignByUserId`,
        newPayload,
        { headers }
      );

      const responseData = response.data;
      setCampaignData(responseData);
      setFilteredData(responseData.data);
      setIsEmpty(responseData.isEmpty);
      console.log(setIsEmpty);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [newPayload]);

  useEffect(() => {
    const groupedData = groupDataByDay(filteredData);
    const sortedGroups = groupedData.sort((groupA, groupB) => {
      const dateA = new Date(groupA[0].createdAt);
      const dateB = new Date(groupB[0].createdAt);
      return sortOrder === "desc" ? dateB - dateA : dateA - dateB;
    });
    const flattenedData = sortedGroups.flatMap((group) => group);
    setFilteredData(flattenedData);
  }, [activeTab, campaignData]);

  const handleSort = () => {
    const sortedData = filteredData.sort((campaignA, campaignB) => {
      const dateA = new Date(campaignA.createdAt);
      const dateB = new Date(campaignB.createdAt);
      return sortOrder === "desc" ? dateB - dateA : dateA - dateB;
    });

    setFilteredData([...sortedData]);
    setSortOrder((prevSortOrder) =>
      prevSortOrder === "desc" ? "asc" : "desc"
    );
  };

  const groupDataByDay = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.createdAt).toLocaleDateString("en-US");
      acc[date] = acc[date] || [];
      acc[date].push(item);
      return acc;
    }, {});

    return Object.values(groupedData);
  };

  const currentRows = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // console.log(currentRows);  

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" variant="info" />
        </div>
      ) : (
        <>
          <SubNav />
          {campaignData && isEmpty ? (
            <Container className="py-4">
              <Row>
                <Col lg={6}>
                  <Col lg={12} className="p-5 rounded-3 border">
                    <h3>Welcome! Your personalized dashboard awaits.</h3>
                    <p>Supercharge your lead quality</p>
                    <Button
                      variant="btn btn-blue"
                      onClick={() => navigate("/createcampagin/create-type")}
                    >
                      Get started
                    </Button>
                  </Col>
                </Col>
                <Col lg={6}>
                  <Col lg={12} className="p-5 rounded-3 border">
                    <h3>Explore plans to get more credits</h3>
                    {/* <p>Supercharge your lead quality</p> */}
                    <Button
                      variant="btn btn-blue"
                      onClick={() => navigate("/account/billing/plans")}
                    >
                      Explore Now
                    </Button>
                  </Col>
                </Col>
                {/* <Col>
                  <img
                    src={DashboardImage}
                    width={600}
                    alt="DashboardImage"
                    className="d-flex m-auto"
                  />
                </Col> */}
              </Row>
            </Container>
          ) : (
            <Container className="py-4">
              <Row>
                <Col lg={6} className="my-auto">
                  <h3 className="fw-bold mb-4">Overview</h3>
                </Col>
                <Col lg={6} className="text-end">
                  <Button
                    variant="btn btn-blue"
                    className=""
                    onClick={() => navigate("/createcampagin/create-type")}
                  >
                    New +
                  </Button>
                </Col>
                <div className="mb-4">
                  <input
                    className="form-control"
                    id="myInput"
                    type="text"
                    placeholder="Search campaigns"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
                <div></div>
                <div>
                  <Table hover className="">
                    <thead>
                      <tr>
                        {/* <td onClick={handleSort} className="text-black">
                          CAMPAIGN NAME{"  "}
                          {sortOrder === "desc" ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </td> */}
                        <td className="text-black">NAME</td>
                        <td className="text-black"></td>
                        {/* <td></td> */}
                      </tr>
                    </thead>
                    <tbody>
                      {currentRows.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => navigate(`/campaignid/${item._id}`)}
                        >
                          <td>{item.campaignName}</td>
                          <td>
                            {item.status === "Pending" ? (
                              <ProgressBar
                                animated
                                variant="warning"
                                now={100}
                                className="w-100"
                                variant="warning"
                                label={"In Progress"}
                              />
                            ) : (
                              <ProgressBar
                                now={100}
                                variant="primary"
                                className="w-100"
                                label={"New"}
                              />
                              // <Badge bg="primary">{ item.status}</Badge>
                            )}
                          </td>
                          {/* <td>{formatDate(item.createdAt)}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
