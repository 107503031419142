import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/auth";
import { baseURL } from "../../utils/constant";
import FirstStep from "../../assets/img/create-type/first step.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import SubNav from "../../Components/subNav";
import { Button, Container } from "react-bootstrap";
import { Row, Col, Badge } from "react-bootstrap";

const NewCampaignTyle = () => {
  const navigate = useNavigate();
  const [disbleButton, setDisableButton] = useState(false);
  const [step, setStep] = useState(1);
  const [leadsData, setLeadsData] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [leadFields, setLeadFields] = useState([
    { name: "firstName", type: "String", label: "First Name" },
    { name: "lastName", type: "String", label: "Last Name" },
    { name: "companyName", type: "String", label: "Company Name" },
    { name: "jobTitle", type: "String", label: "Job Title" },
    { name: "phoneNumber", type: "String", label: "Phone Number" },
    { name: "email", type: "String", label: "Email Address" },
    { name: "industry", type: "String", label: "Industry" }
  ]);

  const bearerToken = getCookie("bearerToken");
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const fetchData = () => {
    axios
      .get(`${baseURL}leadField/getLeadFields`, {
        headers: { ...headers },
      })
      .then((response) => {
        const responseData = response.data;
        console.log(responseData);
        const leadsArray = Array.isArray(responseData)
          ? responseData
          : responseData.data;
        setLeadsData(leadsArray);
        
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleLeadFieldClick = (fieldName) => {
    const isDisabledField = [
      "firstName",
      "lastName",
      "companyName",
      "jobTitle",
      "phoneNumber",
      "email",
      "industry"
    ].includes(fieldName);

    if (isDisabledField) {
      return;
    }

    const isSelected = leadFields.find((field) => field.name === fieldName);

    if (isSelected) {
      const updatedLeadFields = leadFields.filter(
        (field) => field.name !== fieldName
      );
      setDisableButton(true);
      setLeadFields(updatedLeadFields);
      setLeadsData((prevLeadsData) => [...prevLeadsData, isSelected]);
    } else {
      const leadField = leadsData.find((field) => field.name === fieldName);
      if (leadField) {
        setLeadFields((prevSelected) => [...prevSelected, leadField]);
      }
      const updatedLeadsData = leadsData.filter(
        (field) => field.name !== fieldName
      );
      setLeadsData(updatedLeadsData);
    }

    // Disable the clicked button
    const updatedLeadsData = leadsData.map((field) =>
      field.name === fieldName ? { ...field, disabled: true } : field
    );
    setLeadsData(updatedLeadsData);
  };

  const handleDone = () => {
    navigate("/createcampaign", {
      state: {
        leadFields,
        campaignName,
      },
    });
  };

  return (
    <>
      <SubNav />
      <Container className="py-5">
        <Row>
          <Col>
            <h3 className="mb-5 text-center text-black">
              Craft your First Step
            </h3>
            <Col
              lg={8}
              className={`border mx-auto text-start p-5 rounded-3 ${
                step === 1 ? "" : "d-none"
              }`}
            >
              <p className="text-black">Name fashioned for your campaign?</p>
              <Form>
                <Form.Control
                  placeholder="Campaign Name"
                  className="mb-3"
                  required
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                />
                {/* Add a condition to disable the Next button if the input is empty */}
                <Button
                  variant="btn btn-blue"
                  onClick={handleNext}
                  disabled={!campaignName.trim()}
                >
                  Next
                </Button>
              </Form>
            </Col>
            <Col
              lg={10}
              className={`border mx-auto text-start p-5 rounded-3 ${
                step === 2 ? "" : "d-none"
              }`}
            >
              {/* Second step content goes here */}
              <p>Name you've chosen for the campaign</p>
              <Form.Control
                className="mb-5"
                disabled
                value={campaignName}
                required
              />

              <Row className="gap-3">
                <p>Choose the fields which aligns to your campaign</p>
                <Col className="mb-4">
                  {leadsData.map((leadField) => {
                    const isSelected = leadFields.find(
                      (selected) => selected.name === leadField.name
                    );

                    return (
                      <button
                        key={leadField.id}
                        text="dark"
                        className={` btn m-2 ${
                          isSelected ? "btn-warning text-white" : "active"
                        }`}
                        disabled={false}
                        onClick={() => handleLeadFieldClick(leadField.name)}
                      >
                        {leadField.label}
                      </button>
                    );
                  })}
                </Col>

                {/* <Col className="border mb-4 p-3 rounded-3">
                  {leadFields.map((selectedField) => {
                    const isDisabledField = [
                      "firstName",
                      "lastName",
                      "companyName",
                      "jobTitle",
                      "phoneNumber",
                      "email",
                    ].includes(selectedField.name);

                    return (
                      <Badge
                        key={selectedField.name}
                        bg={isDisabledField ? "" : "light"}
                        text="dark"
                        className={`me-2 clickable-badge mb-2 p-3 ${
                          isDisabledField ? "text-muted" : ""
                        }`}
                        onClick={() => handleLeadFieldClick(selectedField.name)}
                      >
                        {selectedField.label}
                        {!isDisabledField && (
                          <>
                            <span className="ms-1" role="button">
                              &times;
                            </span>
                          </>
                        )}
                      </Badge>
                    );
                  })}
                </Col> */}
              </Row>
              <Button variant="btn btn-blue" onClick={handleDone}>
                Done
              </Button>
              <Button variant="secondary" className="ms-2" onClick={handlePrev}>
                Previous
              </Button>
            </Col>
            <Col>
              <img
                src={FirstStep}
                width={550}
                alt="FirstStep"
                className="d-flex m-auto "
              />
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewCampaignTyle;

